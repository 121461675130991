import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setOauth_Personal_Access_ClientsMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addOauth_Personal_Access_Clients, updateOauth_Personal_Access_Clients } from "services/oauth_personal_access_clientsService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const Oauth_Personal_Access_ClientsForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',client_id:'',created_at:'',updated_at:''};
    const initialValue = action === 'edit' ? row : iValue;
    
    
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateOauth_Personal_Access_Clients(values);
                if (response) {
                    dispatch(setOauth_Personal_Access_ClientsMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setOauth_Personal_Access_ClientsMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addOauth_Personal_Access_Clients(values);
                if (response) {
                    dispatch(setOauth_Personal_Access_ClientsMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setOauth_Personal_Access_ClientsMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           id: yup.number().required('id is required'),
client_id: yup.number().required('client_id is required'),
created_at: yup.date(),
updated_at: yup.date(),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} Oauth_Personal_Access_Clients
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Oauth_Personal_Access_Clients</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">id</label>
<Form.Control type="text" name="id" className="form-control" value={formik.values.id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.id && !!formik.errors.id}
isValid ={!!formik.touched.id && !formik.errors.id}
></Form.Control>
{
    formik.errors.id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">client_id</label>
<Form.Control type="text" name="client_id" className="form-control" value={formik.values.client_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.client_id && !!formik.errors.client_id}
isValid ={!!formik.touched.client_id && !formik.errors.client_id}
></Form.Control>
{
    formik.errors.client_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.client_id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">created_at</label>
<Form.Control type="text" name="created_at" className="form-control" value={formik.values.created_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.created_at && !!formik.errors.created_at}
isValid ={!!formik.touched.created_at && !formik.errors.created_at}
></Form.Control>
{
    formik.errors.created_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.created_at}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">updated_at</label>
<Form.Control type="text" name="updated_at" className="form-control" value={formik.values.updated_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.updated_at && !!formik.errors.updated_at}
isValid ={!!formik.touched.updated_at && !formik.errors.updated_at}
></Form.Control>
{
    formik.errors.updated_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.updated_at}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

