import { APIService } from "services";

export const getEnquiry = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllEnquiry(pageNo,pageSize);
    }
    else{
        try {
            res = await searchEnquiry(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const getAllEnquiry = (pageno,pagesize) => {
return APIService.api().get(`/enquiry/read.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getOneEnquiry = (id) => {
return APIService.api().get(`/enquiry/read_one.php?id=${id}`)
}
export const searchEnquiry = (key,pageno,pagesize) => {
return APIService.api().get(`/enquiry/search.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const addEnquiry = (data) => {
return APIService.api().post(`/enquiry/create.php`,data)
}
export const updateEnquiry = (data) => {
return APIService.api().post(`/enquiry/update.php`,data)
}
export const deleteEnquiry = (id) => {
return APIService.api().post(`/enquiry/delete.php`,{id:id})
}
