import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ITraining_Records {
id:number,
name?:string,
job_title?:string,
fc_agenda_vetting?:string,
francis_crick_induction?:string,
customer_service_training?:string,
principle_induction?:string,
refresher_01_12_21?:string,
service_excellence?:string,
confidence_communication?:string,
health_wellbeing?:string,
ownership?:string,
leadership?:string,
ambassadorial?:string,
relationships?:string,
general_h_s?:string,
hazardous_substances?:string,
manual_handling?:string,
near_miss_reporting?:string,
slips_and_strips?:string,
violence_and_aggresion?:string,
work_equipment?:string,
workplace_stress?:string,
ltp?:string,
bu1?:string,
bu2?:string,
bu3?:string,
bu5?:string,
bu6?:string,
bu9?:string,
bu10?:string,
bu11?:string,
bu12?:string,
bu13?:string,
au3?:string,
au4?:string,
au5?:string,
au7?:string,
su1?:string,
su4?:string,
su5?:string,
su9?:string,
bicsc_assessor_level?:string,
bicsc_level_1?:string,
bicsc_level_2?:string,
permit_to_work?:string,
iosh?:string,
karcher_sit_on_scrubber_dryer_br150?:string,
_mayflower_eco_chemical_training?:string,
nebosh?:string,
portable_compactor?:string,
trainer_training_on_waste_compactors?:string,
weightron_bpr?:string,
accident_reporting?:string,
principle_health_safety_policy?:string,
first_aid_procedures_appointed_first_aider?:string,
first_aid_at_work_training?:string,
ppe?:string,
eletrical_equipment?:string,
eletrical_checks?:string,
decanting_of_coshh?:string,
coshh?:string,
general_cleaning?:string,
rubber_gloves?:string,
wearing_of_back_pack?:string,
reporting_property_damage?:string,
h_s_training_workbook?:string,
risk_assessments_method_statements?:string,
waste_compactors?:string,
manual_handling_1?:string,
lone_working?:string,
managing_work_at_height?:string,
litter_picker?:string,
waste_management?:string,
waste_compactor?:string,
use_of_safety_signage?:string,
colour_coding?:string,
coronavirus_b_c_p?:string,
coronavirus_guidance?:string,
coronavirus_u_p_g?:string,
infection_control?:string,
safe_use_of_a_mask?:string,
social_disctance?:string,
nhs_covid_19_app?:string,
guideline_for_cleaning_s?:string,
guideline_for_the_vc_sanitation?:string,
self_isolation?:string,
step_ladder_inspection?:string,
cross_contamination?:string,
safatey_footwear?:string,
near_miss_vs_premise_defect?:string,
ask_for_anglela?:string,
waste_colletion_contaminated?:string,
spillage_cleaning_procedures?:string,
slips_trips_and_falls_during_the_winter?:string,
other?:string,
irata?:string,
working_at_height_training?:string,
pasma?:string,
emergency_first_aid_at_work?:string,
__hevo_id?:number,
__hevo__ingested_at?:number,
__hevo__marked_deleted?:boolean
}

interface ITraining_RecordsData {
    list?: Array<ITraining_Records>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: ITraining_RecordsData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const training_recordsSlice = createSlice({
    name: "training_records",
    initialState,
    reducers: {
        setTraining_RecordsList: (state, _action: PayloadAction<ITraining_RecordsData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetTraining_RecordsToInit: (state) => {
            state = initialState;
        },
        setTraining_RecordsMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setTraining_RecordsList, resetTraining_RecordsToInit, setTraining_RecordsMessage } = training_recordsSlice.actions;

export default training_recordsSlice.reducer;

