import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IPassword_Resets {
email:string,
token:string,
created_at?:Date
}

interface IPassword_ResetsData {
    list?: Array<IPassword_Resets>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IPassword_ResetsData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const password_resetsSlice = createSlice({
    name: "password_resets",
    initialState,
    reducers: {
        setPassword_ResetsList: (state, _action: PayloadAction<IPassword_ResetsData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetPassword_ResetsToInit: (state) => {
            state = initialState;
        },
        setPassword_ResetsMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setPassword_ResetsList, resetPassword_ResetsToInit, setPassword_ResetsMessage } = password_resetsSlice.actions;

export default password_resetsSlice.reducer;

