import { combineReducers } from "redux";

import template from "redux/slices/template";
import authToken from "redux/slices/auth";

import audit_logs from "redux/slices/audit_logs";
import attendance from "redux/slices/attendance";
import users from "redux/slices/users";
import training_records from "redux/slices/training_records";
import todo from "redux/slices/todo";
import role_user from "redux/slices/role_user";
import roles from "redux/slices/roles";
import policies from "redux/slices/policies";
import permission_role from "redux/slices/permission_role";
import permissions from "redux/slices/permissions";
import password_resets from "redux/slices/password_resets";
import oauth_refresh_tokens from "redux/slices/oauth_refresh_tokens";
import oauth_personal_access_clients from "redux/slices/oauth_personal_access_clients";
import oauth_clients from "redux/slices/oauth_clients";
import oauth_auth_codes from "redux/slices/oauth_auth_codes";
import oauth_access_tokens from "redux/slices/oauth_access_tokens";
import migrations from "redux/slices/migrations";
import invoice from "redux/slices/invoice";
import inprinciplepolicies from "redux/slices/inprinciplepolicies";
import floor from "redux/slices/floor";
import failed_jobs from "redux/slices/failed_jobs";
import enquiry from "redux/slices/enquiry";
import contract_family from "redux/slices/contract_family";
import contract from "redux/slices/contract";
import building from "redux/slices/building";


const rootReducer = combineReducers({ template,authToken,audit_logs,attendance,users,training_records,todo,role_user,roles,policies,permission_role,permissions,password_resets,oauth_refresh_tokens,oauth_personal_access_clients,oauth_clients,oauth_auth_codes,oauth_access_tokens,migrations,invoice,inprinciplepolicies,floor,failed_jobs,enquiry,contract_family,contract,building });

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

