import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IFloor {
id:number,
name:string,
building_id:number
}

interface IFloorData {
    list?: Array<IFloor>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IFloorData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const floorSlice = createSlice({
    name: "floor",
    initialState,
    reducers: {
        setFloorList: (state, _action: PayloadAction<IFloorData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetFloorToInit: (state) => {
            state = initialState;
        },
        setFloorMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setFloorList, resetFloorToInit, setFloorMessage } = floorSlice.actions;

export default floorSlice.reducer;

