import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setBuildingMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addBuilding, updateBuilding } from "services/buildingService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const BuildingForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',name:'',address:'',postcode:'',city:'',country:'',created_at:'',updated_at:'',deleted_at:'',infogrid_key:'',hygenia_site_id:'',contract_id:''};
    const initialValue = action === 'edit' ? row : iValue;
    
    
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateBuilding(values);
                if (response) {
                    dispatch(setBuildingMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setBuildingMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addBuilding(values);
                if (response) {
                    dispatch(setBuildingMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setBuildingMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           name: yup.string(),
address: yup.string(),
postcode: yup.string(),
city: yup.string(),
country: yup.string(),
created_at: yup.date().required('created_at is required'),
updated_at: yup.date().required('updated_at is required'),
deleted_at: yup.date(),
infogrid_key: yup.string().required('infogrid_key is required'),
hygenia_site_id: yup.string().required('hygenia_site_id is required'),
contract_id: yup.number().required('contract_id is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} Building
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Building</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">name</label>
<Form.Control type="text" name="name" className="form-control" value={formik.values.name}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.name && !!formik.errors.name}
isValid ={!!formik.touched.name && !formik.errors.name}
></Form.Control>
{
    formik.errors.name && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.name}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">address</label>
<Form.Control type="text" name="address" className="form-control" value={formik.values.address}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.address && !!formik.errors.address}
isValid ={!!formik.touched.address && !formik.errors.address}
></Form.Control>
{
    formik.errors.address && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.address}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">postcode</label>
<Form.Control type="text" name="postcode" className="form-control" value={formik.values.postcode}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.postcode && !!formik.errors.postcode}
isValid ={!!formik.touched.postcode && !formik.errors.postcode}
></Form.Control>
{
    formik.errors.postcode && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.postcode}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">city</label>
<Form.Control type="text" name="city" className="form-control" value={formik.values.city}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.city && !!formik.errors.city}
isValid ={!!formik.touched.city && !formik.errors.city}
></Form.Control>
{
    formik.errors.city && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.city}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">country</label>
<Form.Control type="text" name="country" className="form-control" value={formik.values.country}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.country && !!formik.errors.country}
isValid ={!!formik.touched.country && !formik.errors.country}
></Form.Control>
{
    formik.errors.country && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.country}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">created_at</label>
<Form.Control type="text" name="created_at" className="form-control" value={formik.values.created_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.created_at && !!formik.errors.created_at}
isValid ={!!formik.touched.created_at && !formik.errors.created_at}
></Form.Control>
{
    formik.errors.created_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.created_at}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">updated_at</label>
<Form.Control type="text" name="updated_at" className="form-control" value={formik.values.updated_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.updated_at && !!formik.errors.updated_at}
isValid ={!!formik.touched.updated_at && !formik.errors.updated_at}
></Form.Control>
{
    formik.errors.updated_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.updated_at}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">deleted_at</label>
<Form.Control type="text" name="deleted_at" className="form-control" value={formik.values.deleted_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.deleted_at && !!formik.errors.deleted_at}
isValid ={!!formik.touched.deleted_at && !formik.errors.deleted_at}
></Form.Control>
{
    formik.errors.deleted_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.deleted_at}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">infogrid_key</label>
<Form.Control type="text" name="infogrid_key" className="form-control" value={formik.values.infogrid_key}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.infogrid_key && !!formik.errors.infogrid_key}
isValid ={!!formik.touched.infogrid_key && !formik.errors.infogrid_key}
></Form.Control>
{
    formik.errors.infogrid_key && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.infogrid_key}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">hygenia_site_id</label>
<Form.Control type="text" name="hygenia_site_id" className="form-control" value={formik.values.hygenia_site_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.hygenia_site_id && !!formik.errors.hygenia_site_id}
isValid ={!!formik.touched.hygenia_site_id && !formik.errors.hygenia_site_id}
></Form.Control>
{
    formik.errors.hygenia_site_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.hygenia_site_id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">contract_id</label>
<Form.Control type="text" name="contract_id" className="form-control" value={formik.values.contract_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.contract_id && !!formik.errors.contract_id}
isValid ={!!formik.touched.contract_id && !formik.errors.contract_id}
></Form.Control>
{
    formik.errors.contract_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.contract_id}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

