import { APIService } from "services";

export const getInprinciplepolicies = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllInprinciplepolicies(pageNo,pageSize);
    }
    else{
        try {
            res = await searchInprinciplepolicies(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const getAllInprinciplepolicies = (pageno,pagesize) => {
return APIService.api().get(`/inprinciplepolicies/read.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getOneInprinciplepolicies = (id) => {
return APIService.api().get(`/inprinciplepolicies/read_one.php?id=${id}`)
}
export const searchInprinciplepolicies = (key,pageno,pagesize) => {
return APIService.api().get(`/inprinciplepolicies/search.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const addInprinciplepolicies = (data) => {
return APIService.api().post(`/inprinciplepolicies/create.php`,data)
}
export const updateInprinciplepolicies = (data) => {
return APIService.api().post(`/inprinciplepolicies/update.php`,data)
}
export const deleteInprinciplepolicies = (id) => {
return APIService.api().post(`/inprinciplepolicies/delete.php`,{id:id})
}
