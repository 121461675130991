import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IOauth_Access_Tokens {
id:string,
user_id?:number,
client_id:number,
name?:string,
scopes?:string,
revoked:boolean,
created_at?:Date,
updated_at?:Date,
expires_at?:Date
}

interface IOauth_Access_TokensData {
    list?: Array<IOauth_Access_Tokens>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IOauth_Access_TokensData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const oauth_access_tokensSlice = createSlice({
    name: "oauth_access_tokens",
    initialState,
    reducers: {
        setOauth_Access_TokensList: (state, _action: PayloadAction<IOauth_Access_TokensData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetOauth_Access_TokensToInit: (state) => {
            state = initialState;
        },
        setOauth_Access_TokensMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setOauth_Access_TokensList, resetOauth_Access_TokensToInit, setOauth_Access_TokensMessage } = oauth_access_tokensSlice.actions;

export default oauth_access_tokensSlice.reducer;

