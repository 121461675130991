import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IPermissions {
id:number,
title?:string,
created_at?:Date,
updated_at?:Date,
deleted_at?:Date
}

interface IPermissionsData {
    list?: Array<IPermissions>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IPermissionsData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const permissionsSlice = createSlice({
    name: "permissions",
    initialState,
    reducers: {
        setPermissionsList: (state, _action: PayloadAction<IPermissionsData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetPermissionsToInit: (state) => {
            state = initialState;
        },
        setPermissionsMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setPermissionsList, resetPermissionsToInit, setPermissionsMessage } = permissionsSlice.actions;

export default permissionsSlice.reducer;

