import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setInvoiceMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addInvoice, updateInvoice } from "services/invoiceService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const InvoiceForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',user_id:'',contract_id:'',building_id:'',invoice_number:'',date_issue:'',date_due:'',title:'',bill_to:'',address:'',email:'',mobile_no:'',items:'',subtotal:'',status:'',created_at:'',updated_at:'',deleted_at:''};
    const initialValue = action === 'edit' ? row : iValue;
    
    
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateInvoice(values);
                if (response) {
                    dispatch(setInvoiceMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setInvoiceMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addInvoice(values);
                if (response) {
                    dispatch(setInvoiceMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setInvoiceMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           user_id: yup.string(),
contract_id: yup.string(),
building_id: yup.number().required('building_id is required'),
invoice_number: yup.string().required('invoice_number is required'),
date_issue: yup.string(),
date_due: yup.string(),
title: yup.string(),
bill_to: yup.string(),
address: yup.string(),
email: yup.string(),
mobile_no: yup.string(),
items: yup.string(),
subtotal: yup.string(),
status: yup.string(),
created_at: yup.date().required('created_at is required'),
updated_at: yup.date().required('updated_at is required'),
deleted_at: yup.date(),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} Invoice
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Invoice</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">user_id</label>
<Form.Control type="text" name="user_id" className="form-control" value={formik.values.user_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.user_id && !!formik.errors.user_id}
isValid ={!!formik.touched.user_id && !formik.errors.user_id}
></Form.Control>
{
    formik.errors.user_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.user_id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">contract_id</label>
<Form.Control type="text" name="contract_id" className="form-control" value={formik.values.contract_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.contract_id && !!formik.errors.contract_id}
isValid ={!!formik.touched.contract_id && !formik.errors.contract_id}
></Form.Control>
{
    formik.errors.contract_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.contract_id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">building_id</label>
<Form.Control type="text" name="building_id" className="form-control" value={formik.values.building_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.building_id && !!formik.errors.building_id}
isValid ={!!formik.touched.building_id && !formik.errors.building_id}
></Form.Control>
{
    formik.errors.building_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.building_id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">invoice_number</label>
<Form.Control type="text" name="invoice_number" className="form-control" value={formik.values.invoice_number}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.invoice_number && !!formik.errors.invoice_number}
isValid ={!!formik.touched.invoice_number && !formik.errors.invoice_number}
></Form.Control>
{
    formik.errors.invoice_number && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.invoice_number}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">date_issue</label>
<Form.Control type="text" name="date_issue" className="form-control" value={formik.values.date_issue}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.date_issue && !!formik.errors.date_issue}
isValid ={!!formik.touched.date_issue && !formik.errors.date_issue}
></Form.Control>
{
    formik.errors.date_issue && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.date_issue}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">date_due</label>
<Form.Control type="text" name="date_due" className="form-control" value={formik.values.date_due}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.date_due && !!formik.errors.date_due}
isValid ={!!formik.touched.date_due && !formik.errors.date_due}
></Form.Control>
{
    formik.errors.date_due && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.date_due}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">title</label>
<Form.Control type="text" name="title" className="form-control" value={formik.values.title}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.title && !!formik.errors.title}
isValid ={!!formik.touched.title && !formik.errors.title}
></Form.Control>
{
    formik.errors.title && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.title}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">bill_to</label>
<Form.Control type="text" name="bill_to" className="form-control" value={formik.values.bill_to}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.bill_to && !!formik.errors.bill_to}
isValid ={!!formik.touched.bill_to && !formik.errors.bill_to}
></Form.Control>
{
    formik.errors.bill_to && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.bill_to}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">address</label>
<Form.Control type="text" name="address" className="form-control" value={formik.values.address}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.address && !!formik.errors.address}
isValid ={!!formik.touched.address && !formik.errors.address}
></Form.Control>
{
    formik.errors.address && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.address}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">email</label>
<Form.Control type="text" name="email" className="form-control" value={formik.values.email}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.email && !!formik.errors.email}
isValid ={!!formik.touched.email && !formik.errors.email}
></Form.Control>
{
    formik.errors.email && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.email}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">mobile_no</label>
<Form.Control type="text" name="mobile_no" className="form-control" value={formik.values.mobile_no}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.mobile_no && !!formik.errors.mobile_no}
isValid ={!!formik.touched.mobile_no && !formik.errors.mobile_no}
></Form.Control>
{
    formik.errors.mobile_no && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.mobile_no}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">items</label>
<Form.Control type="text" name="items" className="form-control" value={formik.values.items}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.items && !!formik.errors.items}
isValid ={!!formik.touched.items && !formik.errors.items}
></Form.Control>
{
    formik.errors.items && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.items}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">subtotal</label>
<Form.Control type="text" name="subtotal" className="form-control" value={formik.values.subtotal}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.subtotal && !!formik.errors.subtotal}
isValid ={!!formik.touched.subtotal && !formik.errors.subtotal}
></Form.Control>
{
    formik.errors.subtotal && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.subtotal}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">status</label>
<Form.Control type="text" name="status" className="form-control" value={formik.values.status}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.status && !!formik.errors.status}
isValid ={!!formik.touched.status && !formik.errors.status}
></Form.Control>
{
    formik.errors.status && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.status}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">created_at</label>
<Form.Control type="text" name="created_at" className="form-control" value={formik.values.created_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.created_at && !!formik.errors.created_at}
isValid ={!!formik.touched.created_at && !formik.errors.created_at}
></Form.Control>
{
    formik.errors.created_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.created_at}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">updated_at</label>
<Form.Control type="text" name="updated_at" className="form-control" value={formik.values.updated_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.updated_at && !!formik.errors.updated_at}
isValid ={!!formik.touched.updated_at && !formik.errors.updated_at}
></Form.Control>
{
    formik.errors.updated_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.updated_at}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">deleted_at</label>
<Form.Control type="text" name="deleted_at" className="form-control" value={formik.values.deleted_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.deleted_at && !!formik.errors.deleted_at}
isValid ={!!formik.touched.deleted_at && !formik.errors.deleted_at}
></Form.Control>
{
    formik.errors.deleted_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.deleted_at}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

