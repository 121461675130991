import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setOauth_Access_TokensMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addOauth_Access_Tokens, updateOauth_Access_Tokens } from "services/oauth_access_tokensService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const Oauth_Access_TokensForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',user_id:'',client_id:'',name:'',scopes:'',revoked:'',created_at:'',updated_at:'',expires_at:''};
    const initialValue = action === 'edit' ? row : iValue;
    
    
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateOauth_Access_Tokens(values);
                if (response) {
                    dispatch(setOauth_Access_TokensMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setOauth_Access_TokensMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addOauth_Access_Tokens(values);
                if (response) {
                    dispatch(setOauth_Access_TokensMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setOauth_Access_TokensMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           user_id: yup.number(),
client_id: yup.number().required('client_id is required'),
name: yup.string(),
scopes: yup.string(),
revoked: yup.boolean().required('revoked is required'),
created_at: yup.date(),
updated_at: yup.date(),
expires_at: yup.date(),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} Oauth_Access_Tokens
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Oauth_Access_Tokens</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">user_id</label>
<Form.Control type="text" name="user_id" className="form-control" value={formik.values.user_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.user_id && !!formik.errors.user_id}
isValid ={!!formik.touched.user_id && !formik.errors.user_id}
></Form.Control>
{
    formik.errors.user_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.user_id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">client_id</label>
<Form.Control type="text" name="client_id" className="form-control" value={formik.values.client_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.client_id && !!formik.errors.client_id}
isValid ={!!formik.touched.client_id && !formik.errors.client_id}
></Form.Control>
{
    formik.errors.client_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.client_id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">name</label>
<Form.Control type="text" name="name" className="form-control" value={formik.values.name}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.name && !!formik.errors.name}
isValid ={!!formik.touched.name && !formik.errors.name}
></Form.Control>
{
    formik.errors.name && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.name}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">scopes</label>
<Form.Control type="text" name="scopes" className="form-control" value={formik.values.scopes}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.scopes && !!formik.errors.scopes}
isValid ={!!formik.touched.scopes && !formik.errors.scopes}
></Form.Control>
{
    formik.errors.scopes && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.scopes}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">revoked</label>
<Form.Control type="text" name="revoked" className="form-control" value={formik.values.revoked}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.revoked && !!formik.errors.revoked}
isValid ={!!formik.touched.revoked && !formik.errors.revoked}
></Form.Control>
{
    formik.errors.revoked && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.revoked}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">created_at</label>
<Form.Control type="text" name="created_at" className="form-control" value={formik.values.created_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.created_at && !!formik.errors.created_at}
isValid ={!!formik.touched.created_at && !formik.errors.created_at}
></Form.Control>
{
    formik.errors.created_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.created_at}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">updated_at</label>
<Form.Control type="text" name="updated_at" className="form-control" value={formik.values.updated_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.updated_at && !!formik.errors.updated_at}
isValid ={!!formik.touched.updated_at && !formik.errors.updated_at}
></Form.Control>
{
    formik.errors.updated_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.updated_at}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">expires_at</label>
<Form.Control type="text" name="expires_at" className="form-control" value={formik.values.expires_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.expires_at && !!formik.errors.expires_at}
isValid ={!!formik.touched.expires_at && !formik.errors.expires_at}
></Form.Control>
{
    formik.errors.expires_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.expires_at}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

