import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setMigrationsMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addMigrations, updateMigrations } from "services/migrationsService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const MigrationsForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',migration:'',batch:''};
    const initialValue = action === 'edit' ? row : iValue;
    
    
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateMigrations(values);
                if (response) {
                    dispatch(setMigrationsMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setMigrationsMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addMigrations(values);
                if (response) {
                    dispatch(setMigrationsMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setMigrationsMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           migration: yup.string().required('migration is required'),
batch: yup.number().required('batch is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} Migrations
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Migrations</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">migration</label>
<Form.Control type="text" name="migration" className="form-control" value={formik.values.migration}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.migration && !!formik.errors.migration}
isValid ={!!formik.touched.migration && !formik.errors.migration}
></Form.Control>
{
    formik.errors.migration && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.migration}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">batch</label>
<Form.Control type="text" name="batch" className="form-control" value={formik.values.batch}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.batch && !!formik.errors.batch}
isValid ={!!formik.touched.batch && !formik.errors.batch}
></Form.Control>
{
    formik.errors.batch && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.batch}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

