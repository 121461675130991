import { APIService } from "services";

export const getUsers = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllUsers(pageNo,pageSize);
    }
    else{
        try {
            res = await searchUsers(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const getAllUsers = (pageno,pagesize) => {
return APIService.api().get(`/users/read.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getOneUsers = (id) => {
return APIService.api().get(`/users/read_one.php?id=${id}`)
}
export const searchUsers = (key,pageno,pagesize) => {
return APIService.api().get(`/users/search.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const addUsers = (data) => {
return APIService.api().post(`/users/create.php`,data)
}
export const updateUsers = (data) => {
return APIService.api().post(`/users/update.php`,data)
}
export const deleteUsers = (id) => {
return APIService.api().post(`/users/delete.php`,{id:id})
}
