import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IOauth_Clients {
id:number,
user_id?:number,
name:string,
secret?:string,
provider?:string,
redirect:string,
personal_access_client:boolean,
password_client:boolean,
revoked:boolean,
created_at?:Date,
updated_at?:Date
}

interface IOauth_ClientsData {
    list?: Array<IOauth_Clients>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IOauth_ClientsData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const oauth_clientsSlice = createSlice({
    name: "oauth_clients",
    initialState,
    reducers: {
        setOauth_ClientsList: (state, _action: PayloadAction<IOauth_ClientsData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetOauth_ClientsToInit: (state) => {
            state = initialState;
        },
        setOauth_ClientsMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setOauth_ClientsList, resetOauth_ClientsToInit, setOauth_ClientsMessage } = oauth_clientsSlice.actions;

export default oauth_clientsSlice.reducer;

