import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IContract {
id:number,
user_id?:number,
title?:string,
created_at:Date,
updated_at:Date,
deleted_at?:Date
}

interface IContractData {
    list?: Array<IContract>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IContractData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const contractSlice = createSlice({
    name: "contract",
    initialState,
    reducers: {
        setContractList: (state, _action: PayloadAction<IContractData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetContractToInit: (state) => {
            state = initialState;
        },
        setContractMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setContractList, resetContractToInit, setContractMessage } = contractSlice.actions;

export default contractSlice.reducer;

