import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setTodoMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addTodo, updateTodo } from "services/todoService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const TodoForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',user_id:'',title:'',added_date:'',description:'',department:'',comment:'',status:'',priority:'',favorite:'',created_at:'',updated_at:'',deleted_at:''};
    const initialValue = action === 'edit' ? row : iValue;
    
    
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateTodo(values);
                if (response) {
                    dispatch(setTodoMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setTodoMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addTodo(values);
                if (response) {
                    dispatch(setTodoMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setTodoMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           user_id: yup.string(),
title: yup.string(),
added_date: yup.string(),
description: yup.string(),
department: yup.string(),
comment: yup.string(),
status: yup.string().required('status is required'),
priority: yup.string(),
favorite: yup.string().required('favorite is required'),
created_at: yup.date().required('created_at is required'),
updated_at: yup.date().required('updated_at is required'),
deleted_at: yup.string(),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} Todo
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Todo</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">user_id</label>
<Form.Control type="text" name="user_id" className="form-control" value={formik.values.user_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.user_id && !!formik.errors.user_id}
isValid ={!!formik.touched.user_id && !formik.errors.user_id}
></Form.Control>
{
    formik.errors.user_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.user_id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">title</label>
<Form.Control type="text" name="title" className="form-control" value={formik.values.title}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.title && !!formik.errors.title}
isValid ={!!formik.touched.title && !formik.errors.title}
></Form.Control>
{
    formik.errors.title && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.title}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">added_date</label>
<Form.Control type="text" name="added_date" className="form-control" value={formik.values.added_date}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.added_date && !!formik.errors.added_date}
isValid ={!!formik.touched.added_date && !formik.errors.added_date}
></Form.Control>
{
    formik.errors.added_date && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.added_date}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">description</label>
<Form.Control type="text" name="description" className="form-control" value={formik.values.description}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.description && !!formik.errors.description}
isValid ={!!formik.touched.description && !formik.errors.description}
></Form.Control>
{
    formik.errors.description && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.description}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">department</label>
<Form.Control type="text" name="department" className="form-control" value={formik.values.department}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.department && !!formik.errors.department}
isValid ={!!formik.touched.department && !formik.errors.department}
></Form.Control>
{
    formik.errors.department && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.department}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">comment</label>
<Form.Control type="text" name="comment" className="form-control" value={formik.values.comment}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.comment && !!formik.errors.comment}
isValid ={!!formik.touched.comment && !formik.errors.comment}
></Form.Control>
{
    formik.errors.comment && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.comment}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">status</label>
<Form.Control type="text" name="status" className="form-control" value={formik.values.status}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.status && !!formik.errors.status}
isValid ={!!formik.touched.status && !formik.errors.status}
></Form.Control>
{
    formik.errors.status && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.status}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">priority</label>
<Form.Control type="text" name="priority" className="form-control" value={formik.values.priority}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.priority && !!formik.errors.priority}
isValid ={!!formik.touched.priority && !formik.errors.priority}
></Form.Control>
{
    formik.errors.priority && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.priority}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">favorite</label>
<Form.Control type="text" name="favorite" className="form-control" value={formik.values.favorite}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.favorite && !!formik.errors.favorite}
isValid ={!!formik.touched.favorite && !formik.errors.favorite}
></Form.Control>
{
    formik.errors.favorite && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.favorite}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">created_at</label>
<Form.Control type="text" name="created_at" className="form-control" value={formik.values.created_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.created_at && !!formik.errors.created_at}
isValid ={!!formik.touched.created_at && !formik.errors.created_at}
></Form.Control>
{
    formik.errors.created_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.created_at}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">updated_at</label>
<Form.Control type="text" name="updated_at" className="form-control" value={formik.values.updated_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.updated_at && !!formik.errors.updated_at}
isValid ={!!formik.touched.updated_at && !formik.errors.updated_at}
></Form.Control>
{
    formik.errors.updated_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.updated_at}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">deleted_at</label>
<Form.Control type="text" name="deleted_at" className="form-control" value={formik.values.deleted_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.deleted_at && !!formik.errors.deleted_at}
isValid ={!!formik.touched.deleted_at && !formik.errors.deleted_at}
></Form.Control>
{
    formik.errors.deleted_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.deleted_at}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

