import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IFailed_Jobs {
id:number,
uuid:string,
connection:string,
queue:string,
payload:string,
exception:string,
failed_at:Date
}

interface IFailed_JobsData {
    list?: Array<IFailed_Jobs>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IFailed_JobsData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const failed_jobsSlice = createSlice({
    name: "failed_jobs",
    initialState,
    reducers: {
        setFailed_JobsList: (state, _action: PayloadAction<IFailed_JobsData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetFailed_JobsToInit: (state) => {
            state = initialState;
        },
        setFailed_JobsMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setFailed_JobsList, resetFailed_JobsToInit, setFailed_JobsMessage } = failed_jobsSlice.actions;

export default failed_jobsSlice.reducer;

