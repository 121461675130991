import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ITodo {
id:number,
user_id?:string,
title?:string,
added_date?:string,
description?:string,
department?:string,
comment?:string,
status:string,
priority?:string,
favorite:string,
created_at:Date,
updated_at:Date,
deleted_at?:string
}

interface ITodoData {
    list?: Array<ITodo>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: ITodoData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const todoSlice = createSlice({
    name: "todo",
    initialState,
    reducers: {
        setTodoList: (state, _action: PayloadAction<ITodoData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetTodoToInit: (state) => {
            state = initialState;
        },
        setTodoMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setTodoList, resetTodoToInit, setTodoMessage } = todoSlice.actions;

export default todoSlice.reducer;

