import { APIService } from "services";

export const getTraining_Records = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllTraining_Records(pageNo,pageSize);
    }
    else{
        try {
            res = await searchTraining_Records(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const getAllTraining_Records = (pageno,pagesize) => {
return APIService.api().get(`/training_records/read.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getOneTraining_Records = (id) => {
return APIService.api().get(`/training_records/read_one.php?id=${id}`)
}
export const searchTraining_Records = (key,pageno,pagesize) => {
return APIService.api().get(`/training_records/search.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const addTraining_Records = (data) => {
return APIService.api().post(`/training_records/create.php`,data)
}
export const updateTraining_Records = (data) => {
return APIService.api().post(`/training_records/update.php`,data)
}
export const deleteTraining_Records = (id) => {
return APIService.api().post(`/training_records/delete.php`,{id:id})
}
