import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IBuilding {
id:number,
name?:string,
address?:string,
postcode?:string,
city?:string,
country?:string,
created_at:Date,
updated_at:Date,
deleted_at?:Date,
infogrid_key:string,
hygenia_site_id:string,
contract_id:number
}

interface IBuildingData {
    list?: Array<IBuilding>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IBuildingData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const buildingSlice = createSlice({
    name: "building",
    initialState,
    reducers: {
        setBuildingList: (state, _action: PayloadAction<IBuildingData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetBuildingToInit: (state) => {
            state = initialState;
        },
        setBuildingMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setBuildingList, resetBuildingToInit, setBuildingMessage } = buildingSlice.actions;

export default buildingSlice.reducer;

