import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IOauth_Personal_Access_Clients {
id:number,
client_id:number,
created_at?:Date,
updated_at?:Date
}

interface IOauth_Personal_Access_ClientsData {
    list?: Array<IOauth_Personal_Access_Clients>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IOauth_Personal_Access_ClientsData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const oauth_personal_access_clientsSlice = createSlice({
    name: "oauth_personal_access_clients",
    initialState,
    reducers: {
        setOauth_Personal_Access_ClientsList: (state, _action: PayloadAction<IOauth_Personal_Access_ClientsData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetOauth_Personal_Access_ClientsToInit: (state) => {
            state = initialState;
        },
        setOauth_Personal_Access_ClientsMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setOauth_Personal_Access_ClientsList, resetOauth_Personal_Access_ClientsToInit, setOauth_Personal_Access_ClientsMessage } = oauth_personal_access_clientsSlice.actions;

export default oauth_personal_access_clientsSlice.reducer;

