import { APIService } from "services";

export const getInvoice = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllInvoice(pageNo,pageSize);
    }
    else{
        try {
            res = await searchInvoice(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const getAllInvoice = (pageno,pagesize) => {
return APIService.api().get(`/invoice/read.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getOneInvoice = (id) => {
return APIService.api().get(`/invoice/read_one.php?id=${id}`)
}
export const searchInvoice = (key,pageno,pagesize) => {
return APIService.api().get(`/invoice/search.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const addInvoice = (data) => {
return APIService.api().post(`/invoice/create.php`,data)
}
export const updateInvoice = (data) => {
return APIService.api().post(`/invoice/update.php`,data)
}
export const deleteInvoice = (id) => {
return APIService.api().post(`/invoice/delete.php`,{id:id})
}
