import { APIService } from "services";

export const getRoles = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllRoles(pageNo,pageSize);
    }
    else{
        try {
            res = await searchRoles(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const getAllRoles = (pageno,pagesize) => {
return APIService.api().get(`/roles/read.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getOneRoles = (id) => {
return APIService.api().get(`/roles/read_one.php?id=${id}`)
}
export const searchRoles = (key,pageno,pagesize) => {
return APIService.api().get(`/roles/search.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const addRoles = (data) => {
return APIService.api().post(`/roles/create.php`,data)
}
export const updateRoles = (data) => {
return APIService.api().post(`/roles/update.php`,data)
}
export const deleteRoles = (id) => {
return APIService.api().post(`/roles/delete.php`,{id:id})
}
