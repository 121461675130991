import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IOauth_Refresh_Tokens {
id:string,
access_token_id:string,
revoked:boolean,
expires_at?:Date
}

interface IOauth_Refresh_TokensData {
    list?: Array<IOauth_Refresh_Tokens>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IOauth_Refresh_TokensData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const oauth_refresh_tokensSlice = createSlice({
    name: "oauth_refresh_tokens",
    initialState,
    reducers: {
        setOauth_Refresh_TokensList: (state, _action: PayloadAction<IOauth_Refresh_TokensData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetOauth_Refresh_TokensToInit: (state) => {
            state = initialState;
        },
        setOauth_Refresh_TokensMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setOauth_Refresh_TokensList, resetOauth_Refresh_TokensToInit, setOauth_Refresh_TokensMessage } = oauth_refresh_tokensSlice.actions;

export default oauth_refresh_tokensSlice.reducer;

