import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IAudit_Logs {
id:number,
description:string,
subject_id?:number,
subject_type?:string,
user_id?:number,
properties?:string,
host?:string,
created_at?:Date,
updated_at?:Date
}

interface IAudit_LogsData {
    list?: Array<IAudit_Logs>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IAudit_LogsData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const audit_logsSlice = createSlice({
    name: "audit_logs",
    initialState,
    reducers: {
        setAudit_LogsList: (state, _action: PayloadAction<IAudit_LogsData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetAudit_LogsToInit: (state) => {
            state = initialState;
        },
        setAudit_LogsMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setAudit_LogsList, resetAudit_LogsToInit, setAudit_LogsMessage } = audit_logsSlice.actions;

export default audit_logsSlice.reducer;

