import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { resetTraining_RecordsToInit, setTraining_RecordsMessage } from "redux/actions";
import { Button, Card, Col, Form, InputGroup } from "react-bootstrap";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import { deleteTraining_Records } from "services/training_recordsService";
type Props = {
    hideShowForm: (action) => void;
    handleRowEdit: (row) => void;
    getData: (page, pageSize, searchKey) => void;
};
export const Training_RecordsTable: React.FC<Props> = ({ hideShowForm, handleRowEdit, getData }) => {
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState('');
    const [showDelete, setShowDelete] = useState(false);
    const [rowData, setRowData] = useState(undefined);
    const rData = useSelector((state: RootState) => state.training_records);
    const handleSearch = () => {
        if (search.length > 0) {
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, search);
        }
    }
    const handlePerRowsChange = async (newPerPage, page) => {
        await getData(page, newPerPage, '');
    }
    const handlePageChange = (page) => {
        getData(page, rData.pageSize, '');
    };
    const handleRowDeleteClick = (row) => {
        setRowData(row);
        setShowDelete(true);
    }
    useEffect(() => {
        if (rData && rData.list && rData.list.length === 0) {
            dispatch(resetTraining_RecordsToInit());
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
        }
    })
    const handleReset = () => {
        setSearch('');
        dispatch(resetTraining_RecordsToInit());
        getData(Constant.defaultPageNumber, rData.pageSize, '');
    }
    const handleServerDelete = async () => {
        if (rowData) {
            const response = await deleteTraining_Records(rowData.id);
            if (response) {
                dispatch(resetTraining_RecordsToInit());
                dispatch(setTraining_RecordsMessage("Deleted Successfully"));
                getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                setShowDelete(false);
            } else {
                dispatch(setTraining_RecordsMessage("Some error occured!"));
            }
        }
    }

    const handleRowSelection = (row) => {
        console.log(row); // Row Selection Functionality can be written here
    }
    const handleAddButtonClick = () => {
        dispatch(setTraining_RecordsMessage(''));
        hideShowForm('add');
    }

    const columns = [
       {name: 'id', selector: row => row.id, sortable: true},
{name: 'name', selector: row => row.name, sortable: true},
{name: 'job_title', selector: row => row.job_title, sortable: true},
{name: 'fc_agenda_vetting', selector: row => row.fc_agenda_vetting, sortable: true},
{name: 'francis_crick_induction', selector: row => row.francis_crick_induction, sortable: true},
{name: 'customer_service_training', selector: row => row.customer_service_training, sortable: true},
{name: 'principle_induction', selector: row => row.principle_induction, sortable: true},
{name: 'refresher_01_12_21', selector: row => row.refresher_01_12_21, sortable: true},
{name: 'service_excellence', selector: row => row.service_excellence, sortable: true},
{name: 'confidence_communication', selector: row => row.confidence_communication, sortable: true},
{name: 'health_wellbeing', selector: row => row.health_wellbeing, sortable: true},
{name: 'ownership', selector: row => row.ownership, sortable: true},
{name: 'leadership', selector: row => row.leadership, sortable: true},
{name: 'ambassadorial', selector: row => row.ambassadorial, sortable: true},
{name: 'relationships', selector: row => row.relationships, sortable: true},
{name: 'general_h_s', selector: row => row.general_h_s, sortable: true},
{name: 'hazardous_substances', selector: row => row.hazardous_substances, sortable: true},
{name: 'manual_handling', selector: row => row.manual_handling, sortable: true},
{name: 'near_miss_reporting', selector: row => row.near_miss_reporting, sortable: true},
{name: 'slips_and_strips', selector: row => row.slips_and_strips, sortable: true},
{name: 'violence_and_aggresion', selector: row => row.violence_and_aggresion, sortable: true},
{name: 'work_equipment', selector: row => row.work_equipment, sortable: true},
{name: 'workplace_stress', selector: row => row.workplace_stress, sortable: true},
{name: 'ltp', selector: row => row.ltp, sortable: true},
{name: 'bu1', selector: row => row.bu1, sortable: true},
{name: 'bu2', selector: row => row.bu2, sortable: true},
{name: 'bu3', selector: row => row.bu3, sortable: true},
{name: 'bu5', selector: row => row.bu5, sortable: true},
{name: 'bu6', selector: row => row.bu6, sortable: true},
{name: 'bu9', selector: row => row.bu9, sortable: true},
{name: 'bu10', selector: row => row.bu10, sortable: true},
{name: 'bu11', selector: row => row.bu11, sortable: true},
{name: 'bu12', selector: row => row.bu12, sortable: true},
{name: 'bu13', selector: row => row.bu13, sortable: true},
{name: 'au3', selector: row => row.au3, sortable: true},
{name: 'au4', selector: row => row.au4, sortable: true},
{name: 'au5', selector: row => row.au5, sortable: true},
{name: 'au7', selector: row => row.au7, sortable: true},
{name: 'su1', selector: row => row.su1, sortable: true},
{name: 'su4', selector: row => row.su4, sortable: true},
{name: 'su5', selector: row => row.su5, sortable: true},
{name: 'su9', selector: row => row.su9, sortable: true},
{name: 'bicsc_assessor_level', selector: row => row.bicsc_assessor_level, sortable: true},
{name: 'bicsc_level_1', selector: row => row.bicsc_level_1, sortable: true},
{name: 'bicsc_level_2', selector: row => row.bicsc_level_2, sortable: true},
{name: 'permit_to_work', selector: row => row.permit_to_work, sortable: true},
{name: 'iosh', selector: row => row.iosh, sortable: true},
{name: 'karcher_sit_on_scrubber_dryer_br150', selector: row => row.karcher_sit_on_scrubber_dryer_br150, sortable: true},
{name: '_mayflower_eco_chemical_training', selector: row => row._mayflower_eco_chemical_training, sortable: true},
{name: 'nebosh', selector: row => row.nebosh, sortable: true},
{name: 'portable_compactor', selector: row => row.portable_compactor, sortable: true},
{name: 'trainer_training_on_waste_compactors', selector: row => row.trainer_training_on_waste_compactors, sortable: true},
{name: 'weightron_bpr', selector: row => row.weightron_bpr, sortable: true},
{name: 'accident_reporting', selector: row => row.accident_reporting, sortable: true},
{name: 'principle_health_safety_policy', selector: row => row.principle_health_safety_policy, sortable: true},
{name: 'first_aid_procedures_appointed_first_aider', selector: row => row.first_aid_procedures_appointed_first_aider, sortable: true},
{name: 'first_aid_at_work_training', selector: row => row.first_aid_at_work_training, sortable: true},
{name: 'ppe', selector: row => row.ppe, sortable: true},
{name: 'eletrical_equipment', selector: row => row.eletrical_equipment, sortable: true},
{name: 'eletrical_checks', selector: row => row.eletrical_checks, sortable: true},
{name: 'decanting_of_coshh', selector: row => row.decanting_of_coshh, sortable: true},
{name: 'coshh', selector: row => row.coshh, sortable: true},
{name: 'general_cleaning', selector: row => row.general_cleaning, sortable: true},
{name: 'rubber_gloves', selector: row => row.rubber_gloves, sortable: true},
{name: 'wearing_of_back_pack', selector: row => row.wearing_of_back_pack, sortable: true},
{name: 'reporting_property_damage', selector: row => row.reporting_property_damage, sortable: true},
{name: 'h_s_training_workbook', selector: row => row.h_s_training_workbook, sortable: true},
{name: 'risk_assessments_method_statements', selector: row => row.risk_assessments_method_statements, sortable: true},
{name: 'waste_compactors', selector: row => row.waste_compactors, sortable: true},
{name: 'manual_handling_1', selector: row => row.manual_handling_1, sortable: true},
{name: 'lone_working', selector: row => row.lone_working, sortable: true},
{name: 'managing_work_at_height', selector: row => row.managing_work_at_height, sortable: true},
{name: 'litter_picker', selector: row => row.litter_picker, sortable: true},
{name: 'waste_management', selector: row => row.waste_management, sortable: true},
{name: 'waste_compactor', selector: row => row.waste_compactor, sortable: true},
{name: 'use_of_safety_signage', selector: row => row.use_of_safety_signage, sortable: true},
{name: 'colour_coding', selector: row => row.colour_coding, sortable: true},
{name: 'coronavirus_b_c_p', selector: row => row.coronavirus_b_c_p, sortable: true},
{name: 'coronavirus_guidance', selector: row => row.coronavirus_guidance, sortable: true},
{name: 'coronavirus_u_p_g', selector: row => row.coronavirus_u_p_g, sortable: true},
{name: 'infection_control', selector: row => row.infection_control, sortable: true},
{name: 'safe_use_of_a_mask', selector: row => row.safe_use_of_a_mask, sortable: true},
{name: 'social_disctance', selector: row => row.social_disctance, sortable: true},
{name: 'nhs_covid_19_app', selector: row => row.nhs_covid_19_app, sortable: true},
{name: 'guideline_for_cleaning_s', selector: row => row.guideline_for_cleaning_s, sortable: true},
{name: 'guideline_for_the_vc_sanitation', selector: row => row.guideline_for_the_vc_sanitation, sortable: true},
{name: 'self_isolation', selector: row => row.self_isolation, sortable: true},
{name: 'step_ladder_inspection', selector: row => row.step_ladder_inspection, sortable: true},
{name: 'cross_contamination', selector: row => row.cross_contamination, sortable: true},
{name: 'safatey_footwear', selector: row => row.safatey_footwear, sortable: true},
{name: 'near_miss_vs_premise_defect', selector: row => row.near_miss_vs_premise_defect, sortable: true},
{name: 'ask_for_anglela', selector: row => row.ask_for_anglela, sortable: true},
{name: 'waste_colletion_contaminated', selector: row => row.waste_colletion_contaminated, sortable: true},
{name: 'spillage_cleaning_procedures', selector: row => row.spillage_cleaning_procedures, sortable: true},
{name: 'slips_trips_and_falls_during_the_winter', selector: row => row.slips_trips_and_falls_during_the_winter, sortable: true},
{name: 'other', selector: row => row.other, sortable: true},
{name: 'irata', selector: row => row.irata, sortable: true},
{name: 'working_at_height_training', selector: row => row.working_at_height_training, sortable: true},
{name: 'pasma', selector: row => row.pasma, sortable: true},
{name: 'emergency_first_aid_at_work', selector: row => row.emergency_first_aid_at_work, sortable: true},
{name: '__hevo_id', selector: row => row.__hevo_id, sortable: true},
{name: '__hevo__ingested_at', selector: row => row.__hevo__ingested_at, sortable: true},
{name: '__hevo__marked_deleted', selector: row => row.__hevo__marked_deleted, sortable: true},

        ,{
            name: '',
            button: true,
            cell: (row) => <Button variant="link" className="btn-sm" onClick={() => handleRowEdit(row)}>Edit</Button>,
        },
        {
            name: '',
            button: true,
            cell: (row) => <Button variant="link" className="btn-sm" onClick={() => handleRowDeleteClick(row)}>Delete</Button>,
        },
    ];
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary">Training_Records List ({rData.totalCount})
                    <Button variant="light" className="btn-circle btn-sm ml-2" onClick={handleReset}><i className="fa fa-refresh"></i></Button>
                    <Button className="btn-icon-split float-right" onClick={handleAddButtonClick}>
                        <span className="icon text-white-50">
                            <i className="fas fa-add"></i>
                        </span>
                        <span className="text">Add New</span>
                    </Button></h6>

            </Card.Header>
            <Card.Body>
                <Col className="ml-auto" md={3} xs={12} xl={3}>
                    <InputGroup>
                        <Form.Control
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-search"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <Button disabled={search.length <= 2} variant="dark" className="rounded-0 rounded-right" id="button-search" onClick={handleSearch}>
                            Search
                        </Button>
                    </InputGroup>
                </Col>
                <div className="table-responsive">
                    <DataTable
                        selectableRows={true}
                        onSelectedRowsChange={handleRowSelection}
                        paginationPerPage={Constant.defaultPageNumber}
                        paginationRowsPerPageOptions={Constant.paginationRowsPerPageOptions}
                        columns={columns} data={rData.list}
                        onChangeRowsPerPage={handlePerRowsChange}
                        paginationTotalRows={rData.totalCount}
                        className="table table-bordered"
                        pagination
                        paginationServer
                        onChangePage={handlePageChange}></DataTable>
                </div>
            </Card.Body>
            <ConfirmationModal buttonNegative="Cancel" buttonPositive="Delete" title="Delete Confirmation" show={showDelete} body={"Are you sure?"} onNegative={() => setShowDelete(false)} onPositive={handleServerDelete} />
        </Card>
    );
}

