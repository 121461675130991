import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IAttendance {
id:number,
user_id:string,
date?:string,
building?:string,
time_in?:string,
time_out?:string,
created_at:Date,
updated_at:Date,
deleted_at?:Date,
floor_id:number
}

interface IAttendanceData {
    list?: Array<IAttendance>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IAttendanceData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const attendanceSlice = createSlice({
    name: "attendance",
    initialState,
    reducers: {
        setAttendanceList: (state, _action: PayloadAction<IAttendanceData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetAttendanceToInit: (state) => {
            state = initialState;
        },
        setAttendanceMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setAttendanceList, resetAttendanceToInit, setAttendanceMessage } = attendanceSlice.actions;

export default attendanceSlice.reducer;

