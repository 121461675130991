import { APIService } from "services";

export const getRole_User = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllRole_User(pageNo,pageSize);
    }
    else{
        try {
            res = await searchRole_User(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const getAllRole_User = (pageno,pagesize) => {
return APIService.api().get(`/role_user/read.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getOneRole_User = (id) => {
return APIService.api().get(`/role_user/read_one.php?id=${id}`)
}
export const searchRole_User = (key,pageno,pagesize) => {
return APIService.api().get(`/role_user/search.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const addRole_User = (data) => {
return APIService.api().post(`/role_user/create.php`,data)
}
export const updateRole_User = (data) => {
return APIService.api().post(`/role_user/update.php`,data)
}
export const deleteRole_User = (id) => {
return APIService.api().post(`/role_user/delete.php`,{id:id})
}
