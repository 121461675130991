import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setOauth_Refresh_TokensMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addOauth_Refresh_Tokens, updateOauth_Refresh_Tokens } from "services/oauth_refresh_tokensService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const Oauth_Refresh_TokensForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',access_token_id:'',revoked:'',expires_at:''};
    const initialValue = action === 'edit' ? row : iValue;
    
    
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateOauth_Refresh_Tokens(values);
                if (response) {
                    dispatch(setOauth_Refresh_TokensMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setOauth_Refresh_TokensMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addOauth_Refresh_Tokens(values);
                if (response) {
                    dispatch(setOauth_Refresh_TokensMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setOauth_Refresh_TokensMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           id: yup.string().required('id is required'),
access_token_id: yup.string().required('access_token_id is required'),
revoked: yup.boolean().required('revoked is required'),
expires_at: yup.date(),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} Oauth_Refresh_Tokens
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Oauth_Refresh_Tokens</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">id</label>
<Form.Control type="text" name="id" className="form-control" value={formik.values.id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.id && !!formik.errors.id}
isValid ={!!formik.touched.id && !formik.errors.id}
></Form.Control>
{
    formik.errors.id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">access_token_id</label>
<Form.Control type="text" name="access_token_id" className="form-control" value={formik.values.access_token_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.access_token_id && !!formik.errors.access_token_id}
isValid ={!!formik.touched.access_token_id && !formik.errors.access_token_id}
></Form.Control>
{
    formik.errors.access_token_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.access_token_id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">revoked</label>
<Form.Control type="text" name="revoked" className="form-control" value={formik.values.revoked}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.revoked && !!formik.errors.revoked}
isValid ={!!formik.touched.revoked && !formik.errors.revoked}
></Form.Control>
{
    formik.errors.revoked && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.revoked}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">expires_at</label>
<Form.Control type="text" name="expires_at" className="form-control" value={formik.values.expires_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.expires_at && !!formik.errors.expires_at}
isValid ={!!formik.touched.expires_at && !formik.errors.expires_at}
></Form.Control>
{
    formik.errors.expires_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.expires_at}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

