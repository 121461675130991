import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setUsersMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addUsers, updateUsers } from "services/usersService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const UsersForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',name:'',email:'',email_verified_at:'',verified_at:'',password:'',remember_token:'',verified:'',verification_token:'',created_at:'',updated_at:'',google_id:'',deleted_at:'',token:'',expires_in:'',refresh_token:''};
    const initialValue = action === 'edit' ? row : iValue;
    
    
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateUsers(values);
                if (response) {
                    dispatch(setUsersMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setUsersMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addUsers(values);
                if (response) {
                    dispatch(setUsersMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setUsersMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           name: yup.string().required('name is required'),
email: yup.string().required('email is required'),
email_verified_at: yup.date(),
verified_at: yup.date(),
password: yup.string(),
remember_token: yup.string(),
verified: yup.number(),
verification_token: yup.string(),
created_at: yup.date(),
updated_at: yup.date(),
google_id: yup.string(),
deleted_at: yup.date(),
token: yup.string(),
expires_in: yup.number(),
refresh_token: yup.string(),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} Users
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Users</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">name</label>
<Form.Control type="text" name="name" className="form-control" value={formik.values.name}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.name && !!formik.errors.name}
isValid ={!!formik.touched.name && !formik.errors.name}
></Form.Control>
{
    formik.errors.name && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.name}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">email</label>
<Form.Control type="text" name="email" className="form-control" value={formik.values.email}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.email && !!formik.errors.email}
isValid ={!!formik.touched.email && !formik.errors.email}
></Form.Control>
{
    formik.errors.email && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.email}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">email_verified_at</label>
<Form.Control type="text" name="email_verified_at" className="form-control" value={formik.values.email_verified_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.email_verified_at && !!formik.errors.email_verified_at}
isValid ={!!formik.touched.email_verified_at && !formik.errors.email_verified_at}
></Form.Control>
{
    formik.errors.email_verified_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.email_verified_at}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">verified_at</label>
<Form.Control type="text" name="verified_at" className="form-control" value={formik.values.verified_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.verified_at && !!formik.errors.verified_at}
isValid ={!!formik.touched.verified_at && !formik.errors.verified_at}
></Form.Control>
{
    formik.errors.verified_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.verified_at}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">password</label>
<Form.Control type="text" name="password" className="form-control" value={formik.values.password}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.password && !!formik.errors.password}
isValid ={!!formik.touched.password && !formik.errors.password}
></Form.Control>
{
    formik.errors.password && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.password}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">remember_token</label>
<Form.Control type="text" name="remember_token" className="form-control" value={formik.values.remember_token}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.remember_token && !!formik.errors.remember_token}
isValid ={!!formik.touched.remember_token && !formik.errors.remember_token}
></Form.Control>
{
    formik.errors.remember_token && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.remember_token}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">verified</label>
<Form.Control type="text" name="verified" className="form-control" value={formik.values.verified}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.verified && !!formik.errors.verified}
isValid ={!!formik.touched.verified && !formik.errors.verified}
></Form.Control>
{
    formik.errors.verified && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.verified}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">verification_token</label>
<Form.Control type="text" name="verification_token" className="form-control" value={formik.values.verification_token}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.verification_token && !!formik.errors.verification_token}
isValid ={!!formik.touched.verification_token && !formik.errors.verification_token}
></Form.Control>
{
    formik.errors.verification_token && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.verification_token}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">created_at</label>
<Form.Control type="text" name="created_at" className="form-control" value={formik.values.created_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.created_at && !!formik.errors.created_at}
isValid ={!!formik.touched.created_at && !formik.errors.created_at}
></Form.Control>
{
    formik.errors.created_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.created_at}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">updated_at</label>
<Form.Control type="text" name="updated_at" className="form-control" value={formik.values.updated_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.updated_at && !!formik.errors.updated_at}
isValid ={!!formik.touched.updated_at && !formik.errors.updated_at}
></Form.Control>
{
    formik.errors.updated_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.updated_at}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">google_id</label>
<Form.Control type="text" name="google_id" className="form-control" value={formik.values.google_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.google_id && !!formik.errors.google_id}
isValid ={!!formik.touched.google_id && !formik.errors.google_id}
></Form.Control>
{
    formik.errors.google_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.google_id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">deleted_at</label>
<Form.Control type="text" name="deleted_at" className="form-control" value={formik.values.deleted_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.deleted_at && !!formik.errors.deleted_at}
isValid ={!!formik.touched.deleted_at && !formik.errors.deleted_at}
></Form.Control>
{
    formik.errors.deleted_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.deleted_at}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">token</label>
<Form.Control type="text" name="token" className="form-control" value={formik.values.token}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.token && !!formik.errors.token}
isValid ={!!formik.touched.token && !formik.errors.token}
></Form.Control>
{
    formik.errors.token && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.token}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">expires_in</label>
<Form.Control type="text" name="expires_in" className="form-control" value={formik.values.expires_in}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.expires_in && !!formik.errors.expires_in}
isValid ={!!formik.touched.expires_in && !formik.errors.expires_in}
></Form.Control>
{
    formik.errors.expires_in && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.expires_in}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">refresh_token</label>
<Form.Control type="text" name="refresh_token" className="form-control" value={formik.values.refresh_token}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.refresh_token && !!formik.errors.refresh_token}
isValid ={!!formik.touched.refresh_token && !formik.errors.refresh_token}
></Form.Control>
{
    formik.errors.refresh_token && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.refresh_token}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

