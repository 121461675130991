import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from "./login";
import Register from "./register";
import AuthenticatedRoute from "components/auth";
import { Dashboard } from "components/dashboard";
import { FileUpload } from "components/upload";
import { NotFound } from "./404";
import { Audit_Logs, Attendance, Users, Training_Records, Todo, Role_User, Roles, Policies, Permission_Role, Permissions, Password_Resets, Oauth_Refresh_Tokens, Oauth_Personal_Access_Clients, Oauth_Clients, Oauth_Auth_Codes, Oauth_Access_Tokens, Migrations, Invoice, Inprinciplepolicies, Floor, Failed_Jobs, Enquiry, Contract_Family, Contract, Building} from "components";
const Pages: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />}>
        </Route>
        <Route path="/register" element={<Register />}>
        </Route>
        <Route path="/dashboard" element={<AuthenticatedRoute element={<Dashboard />} />}></Route>
        <Route path="/upload" element={<AuthenticatedRoute element={<FileUpload />} />}></Route>
        <Route path="/audit_logs" element={<AuthenticatedRoute element={<Audit_Logs />} />}></Route>
<Route path="/attendance" element={<AuthenticatedRoute element={<Attendance />} />}></Route>
<Route path="/users" element={<AuthenticatedRoute element={<Users />} />}></Route>
<Route path="/training_records" element={<AuthenticatedRoute element={<Training_Records />} />}></Route>
<Route path="/todo" element={<AuthenticatedRoute element={<Todo />} />}></Route>
<Route path="/role_user" element={<AuthenticatedRoute element={<Role_User />} />}></Route>
<Route path="/roles" element={<AuthenticatedRoute element={<Roles />} />}></Route>
<Route path="/policies" element={<AuthenticatedRoute element={<Policies />} />}></Route>
<Route path="/permission_role" element={<AuthenticatedRoute element={<Permission_Role />} />}></Route>
<Route path="/permissions" element={<AuthenticatedRoute element={<Permissions />} />}></Route>
<Route path="/password_resets" element={<AuthenticatedRoute element={<Password_Resets />} />}></Route>
<Route path="/oauth_refresh_tokens" element={<AuthenticatedRoute element={<Oauth_Refresh_Tokens />} />}></Route>
<Route path="/oauth_personal_access_clients" element={<AuthenticatedRoute element={<Oauth_Personal_Access_Clients />} />}></Route>
<Route path="/oauth_clients" element={<AuthenticatedRoute element={<Oauth_Clients />} />}></Route>
<Route path="/oauth_auth_codes" element={<AuthenticatedRoute element={<Oauth_Auth_Codes />} />}></Route>
<Route path="/oauth_access_tokens" element={<AuthenticatedRoute element={<Oauth_Access_Tokens />} />}></Route>
<Route path="/migrations" element={<AuthenticatedRoute element={<Migrations />} />}></Route>
<Route path="/invoice" element={<AuthenticatedRoute element={<Invoice />} />}></Route>
<Route path="/inprinciplepolicies" element={<AuthenticatedRoute element={<Inprinciplepolicies />} />}></Route>
<Route path="/floor" element={<AuthenticatedRoute element={<Floor />} />}></Route>
<Route path="/failed_jobs" element={<AuthenticatedRoute element={<Failed_Jobs />} />}></Route>
<Route path="/enquiry" element={<AuthenticatedRoute element={<Enquiry />} />}></Route>
<Route path="/contract_family" element={<AuthenticatedRoute element={<Contract_Family />} />}></Route>
<Route path="/contract" element={<AuthenticatedRoute element={<Contract />} />}></Route>
<Route path="/building" element={<AuthenticatedRoute element={<Building />} />}></Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Pages;

