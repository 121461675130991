export const MenuItems = [
    { title: 'Dashboard', path: '/dashboard', icon: 'fas fa-fw fa-tachometer-alt', subMenu: [] },
    { title: 'File Upload', path: '/upload', icon: 'fas fa-fw fa-upload', subMenu: [] },
    { title: 'Audit_Logs', path: '/audit_logs', icon: 'fas fa-fw fa-table',subMenu: []},
{ title: 'Attendance', path: '/attendance', icon: 'fas fa-fw fa-table',subMenu: []},
{ title: 'Users', path: '/users', icon: 'fas fa-fw fa-table',subMenu: []},
{ title: 'Training_Records', path: '/training_records', icon: 'fas fa-fw fa-table',subMenu: []},
{ title: 'Todo', path: '/todo', icon: 'fas fa-fw fa-table',subMenu: []},
{ title: 'Role_User', path: '/role_user', icon: 'fas fa-fw fa-table',subMenu: []},
{ title: 'Roles', path: '/roles', icon: 'fas fa-fw fa-table',subMenu: []},
{ title: 'Policies', path: '/policies', icon: 'fas fa-fw fa-table',subMenu: []},
{ title: 'Permission_Role', path: '/permission_role', icon: 'fas fa-fw fa-table',subMenu: []},
{ title: 'Permissions', path: '/permissions', icon: 'fas fa-fw fa-table',subMenu: []},
{ title: 'Password_Resets', path: '/password_resets', icon: 'fas fa-fw fa-table',subMenu: []},
{ title: 'Oauth_Refresh_Tokens', path: '/oauth_refresh_tokens', icon: 'fas fa-fw fa-table',subMenu: []},
{ title: 'Oauth_Personal_Access_Clients', path: '/oauth_personal_access_clients', icon: 'fas fa-fw fa-table',subMenu: []},
{ title: 'Oauth_Clients', path: '/oauth_clients', icon: 'fas fa-fw fa-table',subMenu: []},
{ title: 'Oauth_Auth_Codes', path: '/oauth_auth_codes', icon: 'fas fa-fw fa-table',subMenu: []},
{ title: 'Oauth_Access_Tokens', path: '/oauth_access_tokens', icon: 'fas fa-fw fa-table',subMenu: []},
{ title: 'Migrations', path: '/migrations', icon: 'fas fa-fw fa-table',subMenu: []},
{ title: 'Invoice', path: '/invoice', icon: 'fas fa-fw fa-table',subMenu: []},
{ title: 'Inprinciplepolicies', path: '/inprinciplepolicies', icon: 'fas fa-fw fa-table',subMenu: []},
{ title: 'Floor', path: '/floor', icon: 'fas fa-fw fa-table',subMenu: []},
{ title: 'Failed_Jobs', path: '/failed_jobs', icon: 'fas fa-fw fa-table',subMenu: []},
{ title: 'Enquiry', path: '/enquiry', icon: 'fas fa-fw fa-table',subMenu: []},
{ title: 'Contract_Family', path: '/contract_family', icon: 'fas fa-fw fa-table',subMenu: []},
{ title: 'Contract', path: '/contract', icon: 'fas fa-fw fa-table',subMenu: []},
{ title: 'Building', path: '/building', icon: 'fas fa-fw fa-table',subMenu: []},

]
