import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IInvoice {
id:number,
user_id?:string,
contract_id?:string,
building_id:number,
invoice_number:string,
date_issue?:string,
date_due?:string,
title?:string,
bill_to?:string,
address?:string,
email?:string,
mobile_no?:string,
items?:string,
subtotal?:string,
status?:string,
created_at:Date,
updated_at:Date,
deleted_at?:Date
}

interface IInvoiceData {
    list?: Array<IInvoice>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IInvoiceData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const invoiceSlice = createSlice({
    name: "invoice",
    initialState,
    reducers: {
        setInvoiceList: (state, _action: PayloadAction<IInvoiceData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetInvoiceToInit: (state) => {
            state = initialState;
        },
        setInvoiceMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setInvoiceList, resetInvoiceToInit, setInvoiceMessage } = invoiceSlice.actions;

export default invoiceSlice.reducer;

