import { APIService } from "services";

export const getPolicies = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllPolicies(pageNo,pageSize);
    }
    else{
        try {
            res = await searchPolicies(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const getAllPolicies = (pageno,pagesize) => {
return APIService.api().get(`/policies/read.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getOnePolicies = (id) => {
return APIService.api().get(`/policies/read_one.php?id=${id}`)
}
export const searchPolicies = (key,pageno,pagesize) => {
return APIService.api().get(`/policies/search.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const addPolicies = (data) => {
return APIService.api().post(`/policies/create.php`,data)
}
export const updatePolicies = (data) => {
return APIService.api().post(`/policies/update.php`,data)
}
export const deletePolicies = (id) => {
return APIService.api().post(`/policies/delete.php`,{id:id})
}
