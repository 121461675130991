import { APIService } from "services";

export const getOauth_Personal_Access_Clients = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllOauth_Personal_Access_Clients(pageNo,pageSize);
    }
    else{
        try {
            res = await searchOauth_Personal_Access_Clients(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const getAllOauth_Personal_Access_Clients = (pageno,pagesize) => {
return APIService.api().get(`/oauth_personal_access_clients/read.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getOneOauth_Personal_Access_Clients = (id) => {
return APIService.api().get(`/oauth_personal_access_clients/read_one.php?id=${id}`)
}
export const searchOauth_Personal_Access_Clients = (key,pageno,pagesize) => {
return APIService.api().get(`/oauth_personal_access_clients/search.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const addOauth_Personal_Access_Clients = (data) => {
return APIService.api().post(`/oauth_personal_access_clients/create.php`,data)
}
export const updateOauth_Personal_Access_Clients = (data) => {
return APIService.api().post(`/oauth_personal_access_clients/update.php`,data)
}
export const deleteOauth_Personal_Access_Clients = (id) => {
return APIService.api().post(`/oauth_personal_access_clients/delete.php`,{id:id})
}
