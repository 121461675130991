import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setContractMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addContract, updateContract } from "services/contractService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const ContractForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',user_id:'',title:'',created_at:'',updated_at:'',deleted_at:''};
    const initialValue = action === 'edit' ? row : iValue;
    
    
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateContract(values);
                if (response) {
                    dispatch(setContractMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setContractMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addContract(values);
                if (response) {
                    dispatch(setContractMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setContractMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           user_id: yup.number(),
title: yup.string(),
created_at: yup.date().required('created_at is required'),
updated_at: yup.date().required('updated_at is required'),
deleted_at: yup.date(),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} Contract
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Contract</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">user_id</label>
<Form.Control type="text" name="user_id" className="form-control" value={formik.values.user_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.user_id && !!formik.errors.user_id}
isValid ={!!formik.touched.user_id && !formik.errors.user_id}
></Form.Control>
{
    formik.errors.user_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.user_id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">title</label>
<Form.Control type="text" name="title" className="form-control" value={formik.values.title}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.title && !!formik.errors.title}
isValid ={!!formik.touched.title && !formik.errors.title}
></Form.Control>
{
    formik.errors.title && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.title}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">created_at</label>
<Form.Control type="text" name="created_at" className="form-control" value={formik.values.created_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.created_at && !!formik.errors.created_at}
isValid ={!!formik.touched.created_at && !formik.errors.created_at}
></Form.Control>
{
    formik.errors.created_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.created_at}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">updated_at</label>
<Form.Control type="text" name="updated_at" className="form-control" value={formik.values.updated_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.updated_at && !!formik.errors.updated_at}
isValid ={!!formik.touched.updated_at && !formik.errors.updated_at}
></Form.Control>
{
    formik.errors.updated_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.updated_at}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">deleted_at</label>
<Form.Control type="text" name="deleted_at" className="form-control" value={formik.values.deleted_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.deleted_at && !!formik.errors.deleted_at}
isValid ={!!formik.touched.deleted_at && !formik.errors.deleted_at}
></Form.Control>
{
    formik.errors.deleted_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.deleted_at}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

