import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setOauth_ClientsMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addOauth_Clients, updateOauth_Clients } from "services/oauth_clientsService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const Oauth_ClientsForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',user_id:'',name:'',secret:'',provider:'',redirect:'',personal_access_client:'',password_client:'',revoked:'',created_at:'',updated_at:''};
    const initialValue = action === 'edit' ? row : iValue;
    
    
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateOauth_Clients(values);
                if (response) {
                    dispatch(setOauth_ClientsMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setOauth_ClientsMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addOauth_Clients(values);
                if (response) {
                    dispatch(setOauth_ClientsMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setOauth_ClientsMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           id: yup.number().required('id is required'),
user_id: yup.number(),
name: yup.string().required('name is required'),
secret: yup.string(),
provider: yup.string(),
redirect: yup.string().required('redirect is required'),
personal_access_client: yup.boolean().required('personal_access_client is required'),
password_client: yup.boolean().required('password_client is required'),
revoked: yup.boolean().required('revoked is required'),
created_at: yup.date(),
updated_at: yup.date(),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} Oauth_Clients
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Oauth_Clients</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">id</label>
<Form.Control type="text" name="id" className="form-control" value={formik.values.id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.id && !!formik.errors.id}
isValid ={!!formik.touched.id && !formik.errors.id}
></Form.Control>
{
    formik.errors.id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">user_id</label>
<Form.Control type="text" name="user_id" className="form-control" value={formik.values.user_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.user_id && !!formik.errors.user_id}
isValid ={!!formik.touched.user_id && !formik.errors.user_id}
></Form.Control>
{
    formik.errors.user_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.user_id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">name</label>
<Form.Control type="text" name="name" className="form-control" value={formik.values.name}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.name && !!formik.errors.name}
isValid ={!!formik.touched.name && !formik.errors.name}
></Form.Control>
{
    formik.errors.name && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.name}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">secret</label>
<Form.Control type="text" name="secret" className="form-control" value={formik.values.secret}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.secret && !!formik.errors.secret}
isValid ={!!formik.touched.secret && !formik.errors.secret}
></Form.Control>
{
    formik.errors.secret && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.secret}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">provider</label>
<Form.Control type="text" name="provider" className="form-control" value={formik.values.provider}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.provider && !!formik.errors.provider}
isValid ={!!formik.touched.provider && !formik.errors.provider}
></Form.Control>
{
    formik.errors.provider && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.provider}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">redirect</label>
<Form.Control type="text" name="redirect" className="form-control" value={formik.values.redirect}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.redirect && !!formik.errors.redirect}
isValid ={!!formik.touched.redirect && !formik.errors.redirect}
></Form.Control>
{
    formik.errors.redirect && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.redirect}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">personal_access_client</label>
<Form.Control type="text" name="personal_access_client" className="form-control" value={formik.values.personal_access_client}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.personal_access_client && !!formik.errors.personal_access_client}
isValid ={!!formik.touched.personal_access_client && !formik.errors.personal_access_client}
></Form.Control>
{
    formik.errors.personal_access_client && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.personal_access_client}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">password_client</label>
<Form.Control type="text" name="password_client" className="form-control" value={formik.values.password_client}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.password_client && !!formik.errors.password_client}
isValid ={!!formik.touched.password_client && !formik.errors.password_client}
></Form.Control>
{
    formik.errors.password_client && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.password_client}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">revoked</label>
<Form.Control type="text" name="revoked" className="form-control" value={formik.values.revoked}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.revoked && !!formik.errors.revoked}
isValid ={!!formik.touched.revoked && !formik.errors.revoked}
></Form.Control>
{
    formik.errors.revoked && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.revoked}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">created_at</label>
<Form.Control type="text" name="created_at" className="form-control" value={formik.values.created_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.created_at && !!formik.errors.created_at}
isValid ={!!formik.touched.created_at && !formik.errors.created_at}
></Form.Control>
{
    formik.errors.created_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.created_at}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">updated_at</label>
<Form.Control type="text" name="updated_at" className="form-control" value={formik.values.updated_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.updated_at && !!formik.errors.updated_at}
isValid ={!!formik.touched.updated_at && !formik.errors.updated_at}
></Form.Control>
{
    formik.errors.updated_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.updated_at}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

