import { APIService } from "services";

export const getPassword_Resets = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllPassword_Resets(pageNo,pageSize);
    }
    else{
        try {
            res = await searchPassword_Resets(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const getAllPassword_Resets = (pageno,pagesize) => {
return APIService.api().get(`/password_resets/read.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getOnePassword_Resets = (id) => {
return APIService.api().get(`/password_resets/read_one.php?id=${id}`)
}
export const searchPassword_Resets = (key,pageno,pagesize) => {
return APIService.api().get(`/password_resets/search.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const addPassword_Resets = (data) => {
return APIService.api().post(`/password_resets/create.php`,data)
}
export const updatePassword_Resets = (data) => {
return APIService.api().post(`/password_resets/update.php`,data)
}
export const deletePassword_Resets = (email) => {
return APIService.api().post(`/password_resets/delete.php`,{email:email})
}
