import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setRole_UserMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addRole_User, updateRole_User } from "services/role_userService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const Role_UserForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',user_id:'',role_id:''};
    const initialValue = action === 'edit' ? row : iValue;
    
    
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateRole_User(values);
                if (response) {
                    dispatch(setRole_UserMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setRole_UserMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addRole_User(values);
                if (response) {
                    dispatch(setRole_UserMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setRole_UserMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           user_id: yup.number().required('user_id is required'),
role_id: yup.number().required('role_id is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} Role_User
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Role_User</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">user_id</label>
<Form.Control type="text" name="user_id" className="form-control" value={formik.values.user_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.user_id && !!formik.errors.user_id}
isValid ={!!formik.touched.user_id && !formik.errors.user_id}
></Form.Control>
{
    formik.errors.user_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.user_id}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">role_id</label>
<Form.Control type="text" name="role_id" className="form-control" value={formik.values.role_id}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.role_id && !!formik.errors.role_id}
isValid ={!!formik.touched.role_id && !formik.errors.role_id}
></Form.Control>
{
    formik.errors.role_id && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.role_id}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

