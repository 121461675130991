import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IMigrations {
id:number,
migration:string,
batch:number
}

interface IMigrationsData {
    list?: Array<IMigrations>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IMigrationsData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const migrationsSlice = createSlice({
    name: "migrations",
    initialState,
    reducers: {
        setMigrationsList: (state, _action: PayloadAction<IMigrationsData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetMigrationsToInit: (state) => {
            state = initialState;
        },
        setMigrationsMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setMigrationsList, resetMigrationsToInit, setMigrationsMessage } = migrationsSlice.actions;

export default migrationsSlice.reducer;

