import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IRole_User {
id:number,
user_id:number,
role_id:number
}

interface IRole_UserData {
    list?: Array<IRole_User>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IRole_UserData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const role_userSlice = createSlice({
    name: "role_user",
    initialState,
    reducers: {
        setRole_UserList: (state, _action: PayloadAction<IRole_UserData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetRole_UserToInit: (state) => {
            state = initialState;
        },
        setRole_UserMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setRole_UserList, resetRole_UserToInit, setRole_UserMessage } = role_userSlice.actions;

export default role_userSlice.reducer;

