import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IPermission_Role {
id:number,
role_id:number,
permission_id:number
}

interface IPermission_RoleData {
    list?: Array<IPermission_Role>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IPermission_RoleData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const permission_roleSlice = createSlice({
    name: "permission_role",
    initialState,
    reducers: {
        setPermission_RoleList: (state, _action: PayloadAction<IPermission_RoleData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetPermission_RoleToInit: (state) => {
            state = initialState;
        },
        setPermission_RoleMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setPermission_RoleList, resetPermission_RoleToInit, setPermission_RoleMessage } = permission_roleSlice.actions;

export default permission_roleSlice.reducer;

