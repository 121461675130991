import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setFailed_JobsMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addFailed_Jobs, updateFailed_Jobs } from "services/failed_jobsService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const Failed_JobsForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',uuid:'',connection:'',queue:'',payload:'',exception:'',failed_at:''};
    const initialValue = action === 'edit' ? row : iValue;
    
    
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateFailed_Jobs(values);
                if (response) {
                    dispatch(setFailed_JobsMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setFailed_JobsMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addFailed_Jobs(values);
                if (response) {
                    dispatch(setFailed_JobsMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setFailed_JobsMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           uuid: yup.string().required('uuid is required'),
connection: yup.string().required('connection is required'),
queue: yup.string().required('queue is required'),
payload: yup.string().required('payload is required'),
exception: yup.string().required('exception is required'),
failed_at: yup.date().required('failed_at is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} Failed_Jobs
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Failed_Jobs</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">uuid</label>
<Form.Control type="text" name="uuid" className="form-control" value={formik.values.uuid}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.uuid && !!formik.errors.uuid}
isValid ={!!formik.touched.uuid && !formik.errors.uuid}
></Form.Control>
{
    formik.errors.uuid && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.uuid}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">connection</label>
<Form.Control type="text" name="connection" className="form-control" value={formik.values.connection}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.connection && !!formik.errors.connection}
isValid ={!!formik.touched.connection && !formik.errors.connection}
></Form.Control>
{
    formik.errors.connection && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.connection}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">queue</label>
<Form.Control type="text" name="queue" className="form-control" value={formik.values.queue}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.queue && !!formik.errors.queue}
isValid ={!!formik.touched.queue && !formik.errors.queue}
></Form.Control>
{
    formik.errors.queue && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.queue}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">payload</label>
<Form.Control type="text" name="payload" className="form-control" value={formik.values.payload}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.payload && !!formik.errors.payload}
isValid ={!!formik.touched.payload && !formik.errors.payload}
></Form.Control>
{
    formik.errors.payload && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.payload}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">exception</label>
<Form.Control type="text" name="exception" className="form-control" value={formik.values.exception}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.exception && !!formik.errors.exception}
isValid ={!!formik.touched.exception && !formik.errors.exception}
></Form.Control>
{
    formik.errors.exception && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.exception}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">failed_at</label>
<Form.Control type="text" name="failed_at" className="form-control" value={formik.values.failed_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.failed_at && !!formik.errors.failed_at}
isValid ={!!formik.touched.failed_at && !formik.errors.failed_at}
></Form.Control>
{
    formik.errors.failed_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.failed_at}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

